import React from "react";
import antiFraud3 from "../images/img-section-anti-fraud.png";

export const AlterPaymentsAnimation = () => {

    return (
        <picture className="anti-fraud-preview-wrapper">
            <img alt="Integration" src={antiFraud3}/>
        </picture>
    );
}