import React from 'react'
import clickImage from '../../images/img-one-click.png'

const Payment = () => {
    return(
        <section
          className="typical__section typical__padding"
          id="payment-solutions"
        >
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="default">One Click</span>
                    <br />
                    <span className="colorized">Payment Solutions</span>
                  </h2>

                  <p className="description ">
                    Get access to token solution on your payment page with
                    Sizard
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="Payment Solutions" src={clickImage} />
              </picture>
            </div>
          </div>
        </section>
    )
}
export default Payment