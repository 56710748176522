import React from 'react'
import {AlterPaymentsAnimation} from "../../ui/AntiFraudAnimation";

const Secure = () => {
  return (
    <section className="typical__section typical__padding" id="secure">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Anti Fraud</span>
                    <br />
                    <span className="default">System</span>
                  </h2>

                  <p className="description">
                    Recognize and reduce fraud using data-driven engine. Sizard
                    antifraud system working without harming frictionless loyal
                    customer with database over 50 mln worldwide users.
                  </p>

                  <ul className="detailed--list">
                    <li>Rich analytics on fraud performance</li>
                    <li>Custom built engine to suit your business</li>
                    <li>Transaction score system</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="right">
              <AlterPaymentsAnimation/>
            </div>
          </div>
        </section>
  )
}

export default Secure