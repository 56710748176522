import React, { useState } from "react";

const ContactForm = ({ isGetStarted, title, description }) => {
  const [mail, setMail] = useState("");

  const handlerEmail = (e) => {
    setMail(e.target.value);
  };

  if (isGetStarted) {
    return (
      <div className="site__container contact__form--wrapper box">
        <form className="contact__form">
          {title && <legend>{title}</legend>}
          {description && (
            <legend className="description">{description}</legend>
          )}

          <div className="form__row">
              <button type="button">Get Started</button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="site__container contact__form--wrapper">
      <form className="contact__form">
        <legend>Setup your best payment solution with Sizard</legend>
        <div className="form__row">
          <input
            type="email"
            value={mail}
            onChange={(e) => handlerEmail(e)}
            placeholder="Enter your email"
          />
            <button type="button">Send</button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
