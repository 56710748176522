import React from 'react'
import feature1 from '../../images/features/calendar-tick.svg'
import feature2 from '../../images/features/smileys.svg'
import feature3 from '../../images/features/home-trend-down.svg'

const Featured = ({title}) => {
  return (
    <section className="typical__section typical__padding" id="features">
    <div className="site__container">
      <h2>{title}</h2>

      <div className="features__grid--wrapper">
        <div className="features__grid">
          <div className="features__grid-card">
            <div className="features__grid-card-content">
              <div className="icon">
                <img src={feature1} alt="Same Day Funding"/>
              </div>
                <h3>Same Day Funding</h3>
              <p>
                Take your business and financial forecast to the next level
              </p>
            </div>
          </div>
          <div className="features__grid-card">
            <div className="features__grid-card-content">
              <div className="icon">
              <img src={feature2} alt="Satisfaction Rate"/>
              </div>
              <h3>Satisfaction Rate</h3>
              <p>Customer Satisfaction - 97% Payment reliability - 99%</p>
            </div>
          </div>

          <div className="features__grid-card">
            <div className="features__grid-card-content">
              <div className="icon">
                <img src={feature3} alt="Lowest Fees"/>
              </div>
              <h3>Lowest Fees</h3>
              <p>Using end-to-end financial IC++ 0.7%</p>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  </section>
  )
}

export default Featured