import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from './header_footer/Header';
import Footer from './header_footer/Footer';
import Cookie from './Cookie';

import '../assets/layout.css';

const Layout = ({children, footer, hiddenContactBtn , mainbg}) => {
  const [isShow, setShowValue] = useState(false)
  useEffect(()=>{
    setShowValue(true)
  },[])

  const actualBGColor = colorClass => {
    if (colorClass) return colorClass
    return `co-main`
  }

  return (
    <>
      {isShow && <Cookie/>}
      <Header/>
        <main className={actualBGColor(mainbg)}>{children}</main>
        {footer === 'no' ? null :  <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
