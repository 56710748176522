import React, { useState } from "react";
import cardorange from "../images/cardorange.svg";
import cardgreen from "../images/cardgreen.svg";
import cardblack from "../images/cardblack.svg";
import upload from "../images/document-upload.svg";


import "./styles.css";

const Card = () => {
  const [index, setIndex] = useState(0);
  const colors = ["#133632", "#4E6AFF", "#0D0D0D"];
  const delay = 3000;
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <React.Fragment>
      <select className="customizeFont">
        <option value="">Customize font</option>
        <option value="font1">font1</option>
        <option value="font2">font2</option>
      </select>
      <form className="uploadLogo">
        <input type="file" id="fileElem" accept="image/*" multiple />
        <label htmlFor="fileElem">
          <div className="uploadText">
            <p>Upload company logo</p>
            <img src={upload} alt="upload" />
          </div>
        </label>
      </form>
      <div className="cardContainer">
        {colors.map((backgroundColor, idx) => {
          const images = [cardgreen, cardorange, cardblack];
          return (
            <img
              alt="card"
              src={images[idx]}
              className={`card${index === idx ? " active" : ""}`}
              key={idx}
            />
          );
        })}
      </div>
      <div className="emailField">
        <label htmlFor="email">Email</label>
        <input
          type="text"
          placeholder="Enter your email"
          name="email"
          className="input"
        />
      </div>
      <div className="payContainer">
        {colors.map((backgroundColor, idx) => (
          <div
            className={`pay${index === idx ? " active" : ""}`}
            key={idx}
            style={{ backgroundColor }}
          >
            <p>Pay 1,220.00$</p>
          </div>
        ))}
      </div>
      <div className="customizeColor">
        <p>Customize color</p>
        <div className="customizeColorCircle">
          {colors.map((color, idx) => (
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              key={idx}
            >
              <circle
                cx="17.439"
                cy="17.439"
                r="16.9756"
                fill={color}
                key={idx}
                className={index === idx ? "active" : ""}
              />
            </svg>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Card;
