import React from "react";
import Logo from "../../ui/Logo";
import { Link } from "gatsby";

const Header = () => (
  <header className="site__header">
    <div className="navigation">
      <Link to="/">
        <Logo />
      </Link>
    </div>
  </header>
);

export default Header;
