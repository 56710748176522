import React from 'react'
import global from '../../images/global_orange.svg'
import photo from '../../images/client.png';
import supportimg from '../../images/support-person.png';
import sendicon from '../../images/send-white.svg'
import { motion } from 'framer-motion'

const Support = () => {
  const messages = [
        {target: "client", avatar: photo, message: 'Hey, please open new countries to receive payments', time: '13:43'},
        {target: "consultant", avatar: supportimg, message: 'Hello! Sure, which countries do you need?', time: '13:44'},
        {target: "client", avatar: photo, message: 'France and Poland', time: '13:44'},
        {target: "consultant", avatar: supportimg, message: 'The request has been agreed with your personal manager - the countries are already opened!', time: '13:45'},
  ];
  return (
    <section className="typical__section typical__padding" id="support">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Perfect client</span>
                    <br />
                    <span className="default">Support 24/7</span>
                  </h2>
                  <p className="description">
                    Personal support manager will help you from integration to
                    providing to you business insights and advising new products
                    that will perfectly fit for your business.
                  </p>
                  <p className="detail">
                    We know how important your business is.
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <motion.div
                className="notifyAboutSupport"
                initial={{ y: 40, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
              >
                <img src={global} alt="global" />
                <p>Client support in all time zone 24/7</p>
              </motion.div>
              <motion.div
                className="sendBackground"
                initial={{ y: 40, opacity: 0 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
              >
                <img src={sendicon} alt="send" />
              </motion.div>

              <div className="fluid__wrapper supportContainer">
                {messages.map(({target, avatar, message, time}, index) => (
                  <motion.div
                    key={index}
                    className="message"
                    initial={{
                      y: index === 0 ? 0 : 40,
                      opacity: index === 0 ? 1 : 0,
                    }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.5, delay: index * 2 }}
                    viewport={{ once: true }}
                  >
                    <div className={`messageContext ${target}`}>
                      {
                        <img src={avatar} alt="message" />
                      }
                      <div className={`messageText ${target }`}>
                        <p>{message}</p>
                      </div>
                    </div>
                    <span className={`time ${target}`}>{time}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </section>
  )
}

export default Support