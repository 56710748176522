import React from 'react'
// import image from '../images/logotypography.svg'
// import icon from '../images/coinlogo-black.svg'

import image from '../images/sitelogo.svg'

const Logo = () => {
  return (
    <div className='site__logo'>
     
        {/* <div className='logo'> 
            <img  src={icon} alt="logo" className='lg'/> 
            <img  src={iconGreen} alt="logo" className='lg-green'/>
        </div>
        <span className='typography'>
            <img src={image} alt="typography"/>
        </span> */}
      <div style={{opacity: `1`}}>
        <img  src={image } alt="logo"/> 
      </div>
       
    </div>
  )
}

export default Logo