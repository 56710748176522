import React from "react";

import imagehero from "../../images/navigation.png";

const Hero = () => (
  <section id="hero">
    <div className="site__container">
      <herodiv className="top typical__padding">
        <h1>
          <span className="colorized">Multiple Payment </span>
          Methods
        </h1>
        <p className="hero-desc">
          We will find the perfect match for your business - from subscription
          model to alternative payments!
        </p>
      </herodiv>
      <div className="image__container">
        <img src={imagehero} alt="dashboard" />
      </div>
    </div>
  </section>
);

export default Hero;
