import React from 'react'
import analytics from '../../images/analitycs.png'

const Analytics = () => {
  return (
    <section className="typical__section typical__padding" id="analytics">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Analytics</span>
                    <br />
                  </h2>
                  <p className="description ">
                    Product dashboard that help with analytics, forecasts, data
                    exports and refunds.
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="Integration" src={analytics} />
              </picture>
            </div>
          </div>
        </section>
  )
}

export default Analytics