import React from 'react'
import Card from '../../ui/Card'

const CustomCard = () => {
    return(
        <section
          className="typical__section typical__padding"
          id="customized-page"
        >
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Customized</span>
                    <br />
                    <span className="default">payment page</span>
                  </h2>
                  <p className="description ">
                    Customize your payment page to improve your customer
                    experience and conversion with Sizard
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="fluid__wrapper customizeWrapper">
                <Card/>
              </div>
            </div>
          </div>
        </section>
    )
}
export default CustomCard