import React, { useState } from "react";
import { Link } from "gatsby";
import Switch from "@mui/material/Switch";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import "../assets/layout.css";

const Cookie = () => {
  const [isShowCookieNotify, setShowCookieNotify] = useState(
    getCookieConsentValue("sizardCookie")
  );
  const isShowBackground = isShowCookieNotify === undefined;
  const [isShowSettings, setShowSettings] = useState(false);

  return (
    <React.Fragment>
      <div
        className="settingsCookie"
        style={{ display: isShowSettings ? "block" : "none" }}
      >
        <div className="settingsList">
          <div className="headerSettings">
            <div>
              <h2>Cookie settings</h2>
              <p>
                Powered by <a href="#">cookie-consent</a>
              </p>
            </div>
            <button
              className="closeSettings"
              onClick={() => setShowSettings(false)}
            ></button>
          </div>
          <div className="mainSettings">
            <h2>Cookie usage</h2>
            <p>
              All Sizard Cookies use you can find in{" "}
              <Link style={{ color: "black" }} to="/privacy-policy">
                Privacy Policy
              </Link>
            </p>
            <div className="selectWrapper">
              <select id="one">
                <option>Strictly necessary cookies</option>
              </select>
              <label htmlFor="one">
                <Switch className="settingSwitch" defaultChecked={true} />
              </label>
            </div>

            <div className="selectWrapper">
              <select id="two">
                <option>Strictly necessary cookies</option>
              </select>
              <label htmlFor="two">
                <Switch className="settingSwitch" defaultChecked={true} />
              </label>
            </div>

            <div className="selectWrapper">
              <select id="three">
                <option>Strictly necessary cookies</option>
              </select>
              <label htmlFor="three">
                <Switch className="settingSwitch" defaultChecked={true} />
              </label>
            </div>
          </div>
          <div className="footerSettings">
            <div className="controlBtn">
              <button className="btnAccept">Accept all</button>
              <button className="btnDecline">Reject all</button>
            </div>
            <button className="btnDecline">Save settings</button>
          </div>
        </div>
      </div>
      {isShowBackground ? <div className="backgroundForCookie"></div> : null}
      <CookieConsent
        cookieName="sizardCookie"
        onAccept={() => setShowCookieNotify("true")}
        onDecline={() => setShowCookieNotify("true")}
        enableDeclineButton
        declineButtonText="Reject"
        buttonText="Accept"
        containerClasses="cookieNotify"
        contentClasses="cookieContent"
        buttonWrapperClasses="btnCookie"
        buttonClasses="btnAccept"
        declineButtonClasses="btnDecline"
      >
        <span>Hello, would you like a cookie ?</span>
        <p style={{ paddingTop: "10px" }}>
          Sizard is using a cookies to improve your website experience.
          <span onClick={() => setShowSettings(true)}>Manage preferences</span>
        </p>
        <p>Cookie usage</p>
        <p>
          All Sizard Cookies use you can find in{" "}
          <Link style={{ color: "black" }} to="/privacy-policy">
            Privacy Policy
          </Link>
        </p>
      </CookieConsent>
    </React.Fragment>
  );
};

export default Cookie;
