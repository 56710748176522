import React from 'react'
import ContactForm from '../../ui/form/ContactForm'

const Contact = ({isGetStarted}) => {
  return (
    <section id="contact" className="typical__padding">
      <div className="site__container">
        <ContactForm isGetStarted={isGetStarted}/>
      </div>
    </section>
  )
}

export default Contact