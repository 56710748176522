import React from "react";
import Devider from "../../ui/Devider";
import mastercard from '../../images/master.svg';
import pcidss from '../../images/pci-dss.svg';
import applePay from '../../images/apple-pay.svg';
import googlePay from '../../images/google-pay.svg';
import fasterPayments from '../../images/faster-payments.svg';
import gpa from '../../images/gpa.svg';
import volt from '../../images/volt.svg';

const Footer = () => {
  return (
    <footer className="site__footer typical__padding">
      <div className="site__container">
        <div className="top  site__container grid"/>
        <Devider />
        <div className="bottom site__container grid">
          <div className="site__container grid left">
            <div className="grid__row">
              <img alt="mastercard" src={mastercard} />
              <img alt="pci-dss" src={pcidss} />
              <img alt="pci-dss" src={applePay} />
              <img alt="pci-dss" src={googlePay} />
            </div>
            <div className="grid__row">
              <img alt="pci-dss" src={fasterPayments} />
              <img alt="pci-dss" src={gpa} />
              <img alt="pci-dss" src={volt} />
            </div>
          </div>

          <div className="site__container grid right">
            <p>
              Logos, trademarks and service marks of third parties displayed on the Sizard site shall remain the property of their respective owners.
            </p>
            <p>
              Phone number +44 771 947 42 59. Email contact@sizard.net
              167-169 Great Portland Street, Fifth Floor, London, United Kingdom, W1W 5PF.
            </p>
          </div>
        </div>
        <div className="centered-bottom site__container grid">
          <p className="copyright">© Sizard 2024. All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
